import { CHAIN_ID } from './networks'

export type AddressMap = Partial<Record<CHAIN_ID, string>>

export default {
  multiCall: {
    1: '0x2a80799F88896E16f963284C7583f365CD645A1B',
    5: '0x925452ab590bdE90800a6265b4C6E16983194C25',
    56: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    97: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
    11155111: '0x7a37FcaBaCC6b04422f2A625080cEf2D1B4B6E4e',
  },
  purchaseCashierContr: {
    1: '0x6f8DDD15139400fecC1f49e2CB5F0B727379bD3d',
    5: '0x658CB9590E95676F461dFE88C23C50d2b9B3e752',
    56: '0x09C4BEEB22D3783E4B1c67Dba3a78c00fab928c6',
    97: '',
    11155111: '',
  },
  paymentHandlerContr: {
    1: '0xAE5Ea1A74239C8B0B8c1171b422AD61dC63b0217',
    5: '0x658CB9590E95676F461dFE88C23C50d2b9B3e752',
    56: '0x658CB9590E95676F461dFE88C23C50d2b9B3e752',
    97: '',
    11155111: '',
  },
  stakingPoolContr: {
    1: '0x47Ff1EeFd4B1FEdD4090556d584D5f61fAF35a13',
    5: '0x47Ff1EeFd4B1FEdD4090556d584D5f61fAF35a13',
    56: '0x47Ff1EeFd4B1FEdD4090556d584D5f61fAF35a13',
    97: '0x47Ff1EeFd4B1FEdD4090556d584D5f61fAF35a13',
    11155111: '0x4C415cadDC0E51eD03476a0BEC695608Bdc891Ec',

  }

}
